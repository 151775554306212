import {cleanFilenameParts, isFunction, isMac, isObject, isPartiallyVisible} from "@/app/utils/Util";
import { deprecatedTagsStore, deprecatedBrandsStore } from "@/store/deprecated/Stores";

import MetricPickerDialog from '../app/framework/dialogs/metric-picker/MetricPickerDialog.vue';
import {showWhenDialog} from "../app/framework/dialogs/Dialog";
import WarningIndicator, { checkTags, checkBrands, checkProfiles } from "../components/WarningIndicator";
import {copyToClipboard} from "@/app/Clipboard"
import {showErrorDialog} from "@/app/framework/dialogs/Dialog"
import {grouseGet, toGrouseLink} from "@/data/Grouse";
import {
    notifyUser, notifyUserOfError,
    notifyWithHtml,
    notifyWithText,
    showBusyNotification
} from "@/app/framework/notifications/Notifications";
import ShareSectionDialog from "../app/toplevel/dashboards/dialogs/ShareSectionDialog";
import {showTooltipHtml} from "@/components/tooltip/TooltipUtilities";
import {escapeExpression} from "@/app/utils/StringUtils";
import _ from 'underscore';
import moment from "moment";
import {showDialogComponent as showDialog} from "@/app/framework/dialogs/DialogUtilities";
import {LANGUAGES} from "@/app/utils/Language";
import {MEDIA_CATEGORIES} from "@/app/utils/MediaLinks";
import {GENDER_ITEMS} from "@/app/utils/Gender";
import {currentAccountCode} from "@/app/utils/Account";
import {egusi} from "@/store/Services";
import {appendFiltersReadably} from "@/dashboards/filter/FilterParser";

Beef.module("Section").addInitializer(function(startupOptions) {

    this.View = Beef.BoundItemView.extend({

        attributes: function() {
            var id = this.model.id;
            return {class: "row-fluid section section-" + id, 'data-id': id, tabindex: "-1" }
        },

        template: require("@/dashboards/Section.handlebars"),

        regions: {
            widgets: "> .main > .widgets",
            warnings: '> .header .warn-space'
        },

        initialize: function() {
            this.model.view = this;
            this._lastRefreshTime = 0;
            this.listenTo(this.model.getDashboardModel(), "change", this.onDashboardChange, this);
            this.listenTo(this.model.getInteractiveFilterModel(), "change", this.interactiveFilterModelChanged, this);

            const widgets = this.model.get('widgets')
            if (widgets) {
                this.listenTo(widgets, "checkwarnings", this.onWidgetWarningsChange, this)
            }
        },

        events: {
            "click > .header .editable": "edit",
            "click > .header .options": "displayMenu",
            "click .add-graph": "addGraph",
            "mouseover .add-graph": "showGraphTooltip",
            "click .add-drill-down": "addDrillDown",
            "mouseover .add-drill-down": "showDrilldownTooltip",
            "click > .header .start-animation" : "startSectionAnimation",
            "click > .header .stop-animation" : "stopSectionAnimation",
            "click > .header .prev-section" : "prevSection",
            "click > .header .next-section" : "nextSection",
            "paste" : "paste"
        },


        onRender: function() {
            // Title is no longer being set on initial render by BoundItemView, probably related to
            // the dashboard panel now being written in vue. This fixes it in the mean time, and sooner or later
            // these sections will no longer be written in backbone.
            setTimeout(()=>this.$('span[name]').html(this.model.get('title')), 0);
            this.updateTimestamp();
            this.updateWidgets();
            if (!this.model.isEditable()) {
                this.$('.footer').hide();
                this.$('.edit-section').removeClass("editable");
            }
            this.updateDrillDownSelection();
            this.handleWarnings();
        },

        templateHelpers: function() {
            var canAnimate = false;
            var list = this.model.get('widgets');
            if (list) {
                canAnimate = !!list.find(function(c) { return c.view })
            }
            return { canAnimate: canAnimate}
        },

        modelEvents: {
            "change:sections": "updateWidgets",
            "change:_effectiveFilter": "onFilterChange",
            "change:filter": "onFilterChange",
        },

        updateWidgets: function() {
            var list = this.model.get('widgets');
            if (list == null) {    // still loading widgets
                this.widgets.show(new Beef.Empty.View());
            } else if (!this.widgets.currentView || !this.widgets.currentView.collection) {
                this.widgets.show(new Beef.WidgetList.View({collection: list, cache: this.cache, dashboardModel: this.model.getDashboardModel()}));
            }
        },

        onDashboardChange: function() {
            this.updateAnimationButtons();

            var filter = this.model.getFilter();
            this.model.set({
                "_effectiveFilter":filter
            });
        },

        onFilterChange() {
            this.handleWarnings();
        },

        onWidgetWarningsChange() {
            this.handleWarnings();
        },

        async handleWarnings() {
            const warnings = [];
            const widgets = this.model.get('widgets');
            if (widgets && widgets.models) {
                widgets.models.forEach(widget => {
                    if (!widget.generalData) return;
                    const widgetWarnings = widget.generalData.get("warnings");
                    if (widgetWarnings && widgetWarnings.length) {
                        widgetWarnings.forEach(w => warnings.push(Object.assign({
                            widgetCid: widget.cid,
                            caption: widget.get('caption') || '«No caption»'
                        }, w)));
                    }
                })
            }

            let ef = this.model.get('_effectiveFilter') || this.model.get('filter');
            if (ef) {
                const tagFilterCopy = id => {
                    switch (id) {
                        case 'MISSING_TAGS': return "This section uses <strong>tags</strong> that have been deleted from the account";
                        case 'MISSING_TOPICS': return "This section uses <strong>topics</strong> that have been removed from the account";
                        case 'MISSING_SEGMENTS': return "This section uses <strong>CX or Conduct tags</strong> that have been removed from the account";
                    }
                };
                const brandFilterCopy = id => {
                    switch (id) {
                        case 'MISSING_BRAND': return "This section uses a <strong>deleted brand</strong>";
                    }
                };
                const profileFilterCopy = id => {
                    switch (id) {
                        case 'MISSING_PROFILE': return "This section's filter uses a <strong>deleted profile</strong>";
                    }
                };
                try {
                    const tagWarnings = await checkTags(ef, tagFilterCopy);
                    const brandWarnings = await checkBrands(ef, brandFilterCopy);
                    const profileWarnings = await checkProfiles(ef, profileFilterCopy);
                    [...brandWarnings, ...tagWarnings, ...profileWarnings].forEach(w => warnings.push(w));
                } catch (e) {
                    console.error("Check tags or brands failed for filter [" + ef + "]: " + e)
                }
            }

            let vue = null;
            if (warnings.length) {
                if (this.warnings.currentView) {
                    vue = this.warnings.currentView.vm;
                } else {
                    const view = new Beef.VuejsView.View({component: WarningIndicator, props: {tooltip: "This section has warnings"}});
                    this.warnings.show(view);
                    vue = view.vm;
                }
            }
            if (vue) vue.warnings = warnings; // Don't need to create more dom elements if we don't need them
            this.$el.toggleClass("has-warnings", !!warnings.length);
            this.model.set('_warnings', warnings);
            this.model.collection.trigger("checkwarnings");
        },

        displayMenu: function() {
            var that = this;
            var section = this.model;
            var filter = section.getFilter();

            var csvLink = toGrouseLink("/v4/accounts/" + currentAccountCode() + "/mentions.csv", {
                filter: filter,
                select: Beef.MentionPanel.MENTIONS_CSV_SELECT,
                limit: 100000
            });
            var mm = new Backbone.Model({view: this, csvLink: csvLink});

            Beef.MiniMenu.show({
                model: mm,
                template: require("@/dashboards/SectionMenu.handlebars"),
                object: that,
                target: that.getMenuTrigger(),
                onRender: function() {
                    if(!section.isEditable()) {
                        this.$('#edit').addClass("disabled");
                        this.$('#addGraph').addClass("disabled");
                        this.$('#addDrillDown').addClass("disabled");
                        this.$('#moveUp').addClass("disabled");
                        this.$('#moveDown').addClass("disabled");
                        this.$('#copy').addClass("disabled");
                        this.$('#delete').addClass("disabled");
                    }
                    if (that.exporting) this.$('#exportCSV').addClass("disabled");
                }
            });
        },

        /**
         * Ask the contained widgets to redraw themselves.
         */
        refresh: function() {
            this._lastRefreshTime = new Date().getTime();
            if (this.widgets.currentView) this.widgets.currentView.refresh();
            this.updateTimestamp();
        },

        refreshIfStale: function(){
            var mins = this.getAutoRefreshMins();
            if ((this._lastRefreshTime + mins * 60000) < new Date().getTime()) this.refresh();
        },

        /**
         * Ask our widgets to re-layout themselves as-if the browser size has changed.
         */
        layout: function() {
            this.widgets.currentView.collection.trigger("layout")
        },

        getMenuTrigger: function() {
            return $('> .header .options .btn', this.$el);
        },

        /**
         * Edit the section settings. If onClose is a function then it is invoked with true if the user selects ok or
         * false otherwise.
         */
        edit: function(onClose) {
            if (!this.model.isEditable()) return;

            var popup = new Beef.Popup.View({
                closeOnHide: true,
                positions: ["center"],
                alwaysMove: true
            });
            popup.setTarget(this.$el);
            var view = new Beef.SectionSettings.View({
                model: this.model,
                cache: this.cache,
                onClose: onClose
            });
            view.on("close", function(){ popup.hide(); });
            popup.show(view);
        },

        delete: function() {
            if (!this.model.isEditable()) return;

            showWhenDialog("Delete a section?", "Are you sure you want to delete '" + this.model.get('title') + "'?")
                .then(function() {
                    var oldModel = Beef.Sync.cloneModel(this.model);
                    var collection = this.model.collection;
                    var index = collection.indexOf(this.model);

                    this.$el.fadeOut({
                        complete: function() {
                            this.model.destroy();
                            notifyUser({
                                message: "Section <strong>" + escapeExpression(this.model.get('title')) + "</strong> deleted.",
                                icon:  "<i class='symbol-metric'></i>",
                                isEscapedHtml: true,
                                undo: function() {
                                    var newModel = new Beef.Dashboard.SectionModel(oldModel);
                                    var upper = collection.at(index);
                                    collection.add(newModel, {at: index});
                                    collection.owner.save();
                                    collection.trigger("reorder");
                                    var lower = collection.at(index);

                                    if (upper && lower) {
                                        upper.view.$el.before(lower.view.$el);
                                    }

                                    if (lower) {
                                        lower.view.$el[0].scrollIntoView({behavior: "smooth", block: 'center', inline: 'start'});
                                    }
                                    notifyWithText("Your section has been undeleted");
                                }.bind(this)
                            })
                        }.bind(this)
                    });
                }.bind(this));
        },

        copy: function() {
            if (!this.model.isEditable()) return;

            var collection = this.model.collection;
            var id = collection.max(function(o) { return o.id } );
            var data = Object.assign({}, Beef.Sync.cloneModel(this.model), {id: id == null ? 1 : id.id + 1});

            var section = new Beef.Dashboard.SectionModel(data);
            section.set('title', this.model.get('title') ? this.model.get('title') + " duplicate" : "duplicate");

            collection.add(section);
            section.save(null, {action: 'add'});

            var $dashboard = this.$el.closest('.dashboard');
            var $sections = $('.section', $dashboard);
            $sections[$sections.length - 1].scrollIntoView({behavior: "smooth", block: 'center', inline: 'start'});
        },

        copyToClipboard: async function() {
            let data = {...this.model.attributes}
            delete data.id
            delete data.version
            await copyToClipboard(JSON.stringify(data, (k, v) => k && k.charAt(0) === '_' ? undefined : v, 2))
            notifyUser({
                message: "Section copied to clipboard",
                icon:  "<i class='symbol-metric'></i>"
            });
        },

        pastFromClipboard: function() {
            // this is called when the section menu item is clicked
            if (navigator.clipboard && typeof navigator.clipboard.readText === "function") {
                navigator.clipboard.readText().then(text => this.pasteImpl(text))
            } else {    // Firefox doesn't support readText
                const message = isMac() ? "Please use ⌘-V to paste" : "Please use CTRL-V to paste";
                showErrorDialog(message, "Paste")
            }
        },

        paste: function(ev) {
            // this is called on ctrl-v or browser paste menu item pick
            ev = ev.originalEvent
            this.pasteImpl(ev.clipboardData.getData("text/plain"))
        },

        pasteImpl: function(text) {
            let data
            try {
                data = JSON.parse(text)
            } catch (ignore) {
                data = { }
            }
            if (Beef.WidgetRegistry.typeMap[data.type]) {   // pasting a metric
                let collection = this.model.get('widgets')
                let id = collection.max(function(o) { return o.id } )
                data.id = id == null ? 1 : id.id + 1
                var widget = new Beef.Dashboard.WidgetModel(data)
                collection.add(widget)
                widget.save(null, {action: 'add'})
                let $widgets = this.$('.widget')
                $widgets[$widgets.length - 1].scrollIntoView(false)

                notifyUser({
                    message: "Metric pasted.",
                    icon:  "<i class='symbol-metric'></i>",
                    undo() {
                        collection.remove(widget);
                        collection.owner.save();
                        notifyWithHtml("Pasted metric has been removed.", null, "<i class='symbol-metric'></i>");
                    }
                });
            } else if (data.widgets) {  // pasting a section
                let collection = this.model.collection
                let id = collection.max(function(o) { return o.id } )
                data.id = id == null ? 1 : id.id + 1
                let index = collection.models.findIndex(m => m === this.model) + 1
                let section = new Beef.Dashboard.SectionModel(data)
                collection.add(section, { at: index })
                section.save(null, {action: 'add'})
                let $dashboard = this.$el.closest('.dashboard')
                let $sections = $('.section', $dashboard)
                $sections[index].scrollIntoView({behavior: "smooth", block: 'center', inline: 'start'})
            } else {
                showErrorDialog("Only metrics and sections can be pasted", "Paste")
            }
        },

        viewMentions: function() {
            Beef.MentionList.navigateToMentions(this.model.getAncestorProperty('accountCode'), this.model.get('filter'));
        },

        viewAuthors: function() {
            Beef.AuthorsSectionV4.navigateToAuthors(this.model.getAncestorProperty('accountCode'), this.model.get('filter'));
        },

        updateAllViews: function() {
            console.debug("Updating all views");
            this.widgets.currentView.list.currentView.children.each(function(widget) {
                if (!widget.model.generalData.get('_completed')) widget.updateWidgetView(true)
                else widget.render();
            });
        },

        /** Make sure all widgets have a view. */
        ensureWidgetViews: function() {
            this.widgets.currentView.list.currentView.children.each(function(widget) {
                widget.updateWidgetView()
            });
        },

        /** Pass each of our widget views to callback. */
        eachWidget: function(callback) {
            var section = this;
            var views = this.widgets.currentView.list.currentView.children;
            _.each(views, function(widget) { callback(widget, section) });
        },

        unsetXml: function() {
            var list = this.widgets.currentView.collection.models;

            for (var i = 0; i < list.length; ++i) {
                if(list[i].has("_xml")) list[i].unset("_xml")
            }
        },

        /** Get a filename for data saved from this Section. */
        getFilename: function() {
            var parts = [];
            parts.push(this.model.get('title') || this.cid);
            var drillDown = this.getDrillDownSelectionText();
            if (drillDown) parts.push(drillDown);
            return this.model.getDashboardModel().view.getFilename() + "-" + cleanFilenameParts(parts).join('-');
        },

        saveImages: function() {
            this.stopSectionAnimation();
            Beef.Dashboard.saveImages(this.widgets.currentView.collection.models, this.getFilename() + ".zip",
                this.getMenuTrigger());
        },

        exportSurveyResponsesCSV: async function () {
            const busy = showBusyNotification("Exporting your survey data");
            let filter = this.model.get('_effectiveFilter') || this.model.get('filter');

            filter = appendFiltersReadably(filter, "media is consumer");

            let checkStatusPromise = null;

            const checkJobStatus = (busy) => {
                const interval = setInterval(async () => {
                    if (!checkStatusPromise) {
                        try {
                            checkStatusPromise = egusi.post(`/rest/v1/accounts/${currentAccountCode()}/surveys/reports/status`, {
                                filter: filter
                            });

                            let response = await checkStatusPromise;

                            if (response.data.jobState === "COMPLETED") {
                                clearInterval(interval); // Stop the interval
                                const csvContent = response.data.csv;
                                downloadCsv(csvContent);
                                notifyUser("Successfully exported survey report data.");
                                busy.close();
                            } else if (response.data.jobState === "FAILED") {
                                clearInterval(interval); // Stop the interval
                                busy.close();
                            }
                        } catch (error) {
                            console.error("Error checking job status:", error);
                            clearInterval(interval); // Stop the interval on error
                            busy?.close(); // Close the busy state (if applicable)
                            notifyUser("An error occurred while checking the job status.");
                        } finally {
                            checkStatusPromise = null;
                        }
                    }
                }, 5000);
            };

            const downloadCsv = (csvContent) => {
                const blob = new Blob([csvContent], { type: 'text/csv' });
                const link = document.createElement('a');
                link.href = URL.createObjectURL(blob);
                link.download = 'survey_report_' + Date.now().toString() + '.csv';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                URL.revokeObjectURL(link.href);
            }

            try {
                let response = await egusi.post(`/rest/v1/accounts/${currentAccountCode()}/surveys/reports`,{
                    filter: filter
                });
                if (response.data.jobState === "COMPLETED") {
                    const csvContent = response.data.csv;
                    downloadCsv(csvContent);
                    busy.close();
                    notifyUser("Successfully exported survey report data.");
                } else {
                    checkJobStatus(busy);
                }
            } catch (e) {
                console.error(e);
                busy.close();
                notifyUserOfError("There was a problem exporting your survey data");
            }

        },

        exportShiftResponseReportCSV: async function () {
            const busy = showBusyNotification("Exporting your shift response time data");
            let filter = this.model.get('_effectiveFilter') || this.model.get('filter');

            // filter = appendFiltersReadably(filter, "media is consumer");

            let checkStatusPromise = null;

            const checkJobStatus = (busy) => {
                const interval = setInterval(async () => {
                    if (!checkStatusPromise) {
                        try {
                            checkStatusPromise = egusi.post(`/rest/v1/accounts/${currentAccountCode()}/shiftResponseTime/reports/status`, {
                                filter: filter
                            });

                            let response = await checkStatusPromise;

                            if (response.data.jobState === "COMPLETED") {
                                clearInterval(interval); // Stop the interval
                                const csvContent = response.data.csv;
                                downloadCsv(csvContent);
                                notifyUser("Successfully exported survey report data.");
                                busy.close();
                            } else if (response.data.jobState === "FAILED") {
                                clearInterval(interval); // Stop the interval
                                busy.close();
                            }
                        } catch (error) {
                            console.error("Error checking job status:", error);
                            clearInterval(interval); // Stop the interval on error
                            busy?.close(); // Close the busy state (if applicable)
                            notifyUser("An error occurred while checking the job status.");
                        } finally {
                            checkStatusPromise = null;
                        }
                    }
                }, 5000);
            };

            const downloadCsv = (csvContent) => {
                const blob = new Blob([csvContent], { type: 'text/csv' });
                const link = document.createElement('a');
                link.href = URL.createObjectURL(blob);
                link.download = 'interaction_response_report_' + Date.now().toString() + '.csv';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                URL.revokeObjectURL(link.href);
            }

            try {
                let response = await egusi.post(`/rest/v1/accounts/${currentAccountCode()}/shiftResponseTime/reports`,{
                    filter: filter
                });
                if (response.data.jobState === "COMPLETED") {
                    const csvContent = response.data.csv;
                    downloadCsv(csvContent);
                    busy.close();
                    notifyUser("Successfully exported survey report data.");
                } else {
                    checkJobStatus(busy);
                }
            } catch (e) {
                console.error(e);
                busy.close();
                notifyUserOfError("There was a problem exporting your survey data");
            }

        },

        /**
         * Check to see if all widgets have loaded, if not, check again in 100ms
         */
        exportWidgets: function(location, list) {
            if (location == window.location && !this.powerpointModel.get('cancelled')) {
                for (var i = 0; i < list.length; ++i) {
                    if (!list[i].generalData.get('_completed')) {
                        setTimeout(function(){
                            this.exportWidgets(location, list);
                        }.bind(this), 1000);
                        return;
                    }
                }

                var that = this;
                $.ajax({
                    url: "/ppt/open",
                    type: "POST",
                    contentType: "application/json",
                    processData: false,
                    data: JSON.stringify(cloneSection(that.model)),
                    success: function(id) {
                        that.sendWidget(id, list, 0, location);
                    },
                    error: function(error) {
                        console.error(error)
                    }
                });
            }
        },

        sendWidget: function(id, list, i, location) {
            if (location == window.location && !this.powerpointModel.get('cancelled')) {
                var w = list[i];
                var widget = this.prepForExport(w);
                var that = this;
                $.ajax({
                    url: "/ppt/" + id + "/section/" + w.collection.owner.id + "/widget/" + widget.get('id'),
                    type: "POST",
                    contentType: "application/json",
                    processData: false,
                    data: JSON.stringify(cloneWidget(widget)),
                    success: function() {
                        that.powerpointModel.set('position', that.powerpointModel.get('position') + 1);
                        if(i + 1 < list.length) {
                            that.sendWidget(id, list, i + 1, location);
                        } else {
                            $.ajax({
                                url: "/ppt/" + id + "/close",
                                type: "POST",
                                contentType: "application/json",
                                processData: false,
                                data: JSON.stringify({close: true}),
                                success: function(data) {
                                    if (location == window.location && !that.powerpointModel.get('cancelled')) {
                                        that.powerpointModel.set('done', true);
                                        window.location = data.link;
                                        that.unsetXml();
                                    }
                                },
                                error: function() {
                                    that.powerpointModel.set('done', true);
                                    that.unsetXml();
                                }
                            });
                        }
                    },
                    error: function() {
                        that.powerpointModel.set('done', true);
                        that.unsetXml();
                    }
                });
            }
        },

        /**
         * Prepares the section for PPT export by preparing each widget's xml, title, width, height and text
         */
        prepForExport: function(w) {
            // If the widget does not have a width or height set, then set it to its default according to its type
            // or alternatively 2, if its type has no default value
            var widget = new Backbone.Model(Beef.Sync.cloneModel(w));

            var type = widget.get('type');
            if (type) type = Beef.WidgetRegistry.typeMap[type];
            if (type) {
                if (!widget.has('width') && type.width) widget.set('width', type.width, {silent:true});
                else if (!widget.has('width')) widget.set('width', 2, {silent:true});
                if (!widget.has('height') && type.height) widget.set('height', type.height, {silent:true});
                else if (!widget.has('height')) widget.set('height', 2, {silent:true});
                if (!widget.has('caption')) widget.set('caption', type.name, {silent:true});
            }

            // The cid is required to find the widget view and container
            var cid = w.cid;
            var $widget = $('.widget[widget-id="' + cid + '"]', this.$el);
            var $view = $('.widget-container', $widget);

            if (type.name != 'Conversation' && type.group != 'selector') {
                // Find any text in the widget
                var $text = $('td', $view);
                if (!$text) $text = $('.text', $view);
                if ($text) {
                    var text = "";
                    $.each($text, function(i, v) {
                        text = text + '\n' + v.textContent;
                    });

                    if (!text.trim()) {
                        text = '\n' + $text.prevObject[0].textContent;
                    }

                    widget.set('_text', text, {silent:true});
                }

                // Convert widget to xml and save it on model
                var imageData = w.view.prepareImageData('png');
                widget.set('_xml', imageData.xml, {silent:true});
                widget.set('_params', imageData.options, {silent:true});
            }

            return widget;
        },


        /**
         * This is useful for animating the movement of two sections swapping with each other.
         * upper and lower are the sections at their starting position. pulse should be the section
         * whose final position is highlighted.
         */
        animateMovement: function($upper, $lower, $pulse) {
            $upper.toggleClass('short-animated fadeOutDown', true);
            $lower.toggleClass('short-animated fadeOutUp', true);

            setTimeout(function() {
                $upper.css('opacity', 0);
                $lower.css('opacity', 0);

                $upper.before($lower);
                $pulse[0].scrollIntoView(false);

                $upper.toggleClass('fadeOutDown', false);
                $upper.toggleClass('fadeIn', true);

                $lower.toggleClass('fadeOutUp', false);
                $lower.toggleClass('fadeIn', true);

                setTimeout(function() {
                    $upper.css('opacity', 1);
                    $lower.css('opacity', 1);

                    $lower.toggleClass('short-animated fadeIn', false);
                    $upper.toggleClass('short-animated fadeIn', false);

                    $pulse.toggleClass('animated pulse', true);

                    setTimeout(function() {
                        $pulse.toggleClass('animated pulse', false);
                    }, 1050);
                }, 410);
            }, 410);
        },

        moveUp: function() {
            if (!this.model.isEditable()) return;

            var col = this.model.collection;
            var i = _(col.models).indexOf(this.model);

            if (i > 0) {
                var tmp = col.models[i - 1];
                col.models[i - 1] = col.models[i];
                col.models[i] = tmp;
                col.owner.save();
                col.trigger("reorder");

                var $previous = this.$el.prev(),
                    $current = this.$el;

                this.animateMovement($previous, $current, $current);
            }
        },

        moveDown: function() {
            if (!this.model.isEditable()) return;

            var col = this.model.collection;
            var i = _(col.models).indexOf(this.model);

            if (i != col.models.length - 1) {
                var tmp = col.models[i + 1];
                col.models[i + 1] = col.models[i];
                col.models[i] = tmp;
                col.owner.save();
                col.trigger("reorder");

                var $next = this.$el.next(),
                    $current = this.$el;

                this.animateMovement($current, $next, $current);
            }
        },

        addGraph: function(ev) {
            const dialog = showDialog(MetricPickerDialog);
            dialog.$on('add-metric', widget => {
                const widgetView = this.widgets.currentView.addWidget(widget);
                widgetView.$el[0].scrollIntoView({behavior: 'smooth', block: 'nearest', inline: 'start'});
            })
        },

        showGraphTooltip(event) {
            showTooltipHtml(
                event.currentTarget,
                `
                <h1><i class="icon-signal-1"></i> Adding a metric</h1>
                <p>
                    A <em>Metric</em> is a chart, table, or other way to summarise the mentions that 
                    you want to see.                 
                </p>
                `
            )
        },

        addDrillDown: function(ev) {
            this.addGraphImpl("selector", "Select a Drill Down", ev && ev.preventDefault);
        },

        showDrilldownTooltip(event) {
            showTooltipHtml(
                event.currentTarget,
                `<h1><i class="icon-list"></i>Adding a drill down</h1>
                            <p>
                                <em>Drill downs</em> give you useful tools to help you interactively explore
                                the mentions that you're exploring in a dashboard. 
                            </p>
                            `
                )
        },

        addGraphImpl: function(group, title, fromButton) {
            var popup = new Beef.Popup.View({ closeOnHide: true, positions:["center"], plain: true });
            popup.setTarget(this.$el);
            var view = new Beef.WidgetTypeChange.View({
                select: true,
                popup: popup,
                group: group,
                title: title
            });
            view.on('selected', function(widget) {;
                var widgetView = this.widgets.currentView.addWidget(widget);
                widgetView.$el[0].scrollIntoView({behavior: 'smooth', block: 'nearest', inline: 'start'});
            }.bind(this));
            view.on('new-picker', () => this.addGraph() );
            popup.show(view);
        },

        onWidgetViewCreated: function() {
            this.updateAnimationButtons();
        },

        updateAnimationButtons: function() {
            var $ab = this.$('.animation-buttons');
            var canAnimate = this.findWidgetsSupportingAnimation().length > 0 || Beef.Dashboard.isPresentationMode();
            if (canAnimate) {
                $ab.find(".stop-animation").toggle(!!this._animationState);
                $ab.find(".start-animation").toggle(!this._animationState);
            }
            $ab.toggle(canAnimate);
        },

        /**
         * Note that this returns the actual inner widget views not the Widget wrapper view.
         */
        findWidgetsSupportingAnimation: function() {
            var list = this.model.get('widgets');
            var ans = [];
            if (list) {
                list.each(function(c) {
                    if (c.view) {
                        var w = c.view.getContainedWidget();
                        if (w && (typeof w.nextAnimationFrame === 'function')) {
                            if (typeof w.isAnimationSupported !== 'function' || w.isAnimationSupported()) ans.push(w);
                        }
                    }
                });
            }
            return ans;
        },

        startSectionAnimation: function(normalInitialDelay) {
            if (this._animationState) return;
            var list = this.findWidgetsSupportingAnimation();
            this._animationState = {
                ticks: normalInitialDelay ? 0 : -1,
                loadingTicks: 0,
                interval: setInterval(this.nextAnimationFrame.bind(this), 1000)
            };
            this.updateAnimationButtons();
        },

        stopSectionAnimation: function() {
            if (this._animationState) {
                clearInterval(this._animationState.interval);
                this._animationState = null;
                this.updateAnimationButtons();
            }
        },

        nextAnimationFrame: function() {
            var ok = false;
            try {
                var state = this._animationState;
                var list = this.findWidgetsSupportingAnimation();
                var loading = this.isWidgetsLoadingData();
                if (loading) ++state.loadingTicks;
                if (!loading || state.loadingTicks > 30) {
                    var delay = parseInt(this.model.get('animation-delay'));
                    if (isNaN(delay) || delay < 1) delay = 10;
                    if (state.ticks < 0 || ++state.ticks >= delay) {
                        state.ticks = 0;
                        state.loadingTicks = 0;
                        for (var i = list.length - 1; i >= 0; i--) if (!list[i].nextAnimationFrame()) break;

                        // if i == -1 then this was the last frame in this section and we need to move to next
                        if (i < 0 && Beef.Dashboard.isPresentationMode()) this.nextSection();
                    }
                }
                ok = true;
            } finally {
                if (!ok) this.stopSectionAnimation();
            }
        },

        isWidgetsLoadingData: function() {
            var list = this.model.get('widgets');
            if (!list) return false;
            for (var i = 0; i < list.models.length; i++) {
                if (list.models[i].generalData.get('_loading')) return true;
            }
            return false;
        },

        /**
         * If all widgets have set the _completed flag return true. Supposedly this means they have finished
         * loading data and rendering and so on. Widgets that are off screen are not checked. This is used when
         * rendering dashboard sections as images.
         */
        isWidgetsCompleted: function(debug) {
            var list = this.model.get('widgets')
            if (!list) return true
            for (var i = 0; i < list.models.length; i++) {
                let m = list.models[i]
                if (!m.generalData.get('_completed') || !m.generalData.get("_commentsComplete")) {
                    let view = m.view
                    if (isPartiallyVisible(view.$el)) {
                        if (debug) console.log("isWidgetsCompleted [" + m.get('caption') + "] is incomplete and visible")
                        return false
                    }
                }
            }
            if (debug) console.log("isWidgetsCompleted true section [" + this.model.get('title') + "]")
            return true
        },

        onClose: function() {
            this.stopSectionAnimation();
        },

        createSectionLink: function() {
            showDialog(ShareSectionDialog, {
                sectionId: this.model.id,
                sectionName: this.model.attributes?.title,
                dashboardId: this.model.getDashboardModel().id,
                dashboardName: this.model.getDashboardModel().attributes?.name
            });
        },

        viewFullscreen: function() {
            this.model.collection.view.viewFullscreen(this.indexOfModel());
        },

        viewPresentation: function() {
            this.model.collection.view.viewFullscreen(this.indexOfModel(), true);
        },

        viewAsEmail: function() {
            this.model.collection.view.viewFullscreen(this.indexOfModel(), true, {
                email: true,
                bodyClass: "notification-email"
            });
        },

        viewAsPDF: function() {
            this.model.collection.view.viewFullscreen(this.indexOfModel(), true, {
                email: true,
                bodyClass: "pdf"
            });
        },

        onEnterFullscreen: function() {
            this.updateAllViews();
            this.$el.focus();

            var widgets = this.widgets && this.widgets.currentView;

            // We want to ask all widgets to rerender themselves, after
            // all metrics have been given some time to resize (resize is animated)
            if (widgets && widgets.collection && widgets.collection.length) {
                widgets.collection.forEach(function(m) {
                    if (!m) return;
                    var view = m.view;
                    var container = m.view && m.view.container && m.view.container.currentView;
                    if (view && container) setTimeout(function() { container.render() }, 500);
                })
            }
        },

        indexOfModel: function() {
            var collection = this.model.collection;
            for (var i = 0; i < collection.length; i++) if (collection.models[i] == this.model) return i;
            return -1;
        },

        nextSection: function(ev) {
            this.model.collection.view.moveToSection(+1);
        },

        prevSection: function(ev) {
            this.model.collection.view.moveToSection(-1);
        },

        getAutoRefreshMins: function() {
            var mins = parseInt(this.model.get('refresh-interval'));
            if (!mins || isNaN(mins) || mins < 1) mins = 60;
            return mins;
        },

        updateTimestamp: function() {
            this.$('.header .timestamp').text(moment().format("D MMM HH:mm"))
        },

        interactiveFilterModelChanged: function() {
            this.updateDrillDownSelection();
        },

        updateDrillDownSelection: function() {
            var text = this.getDrillDownSelectionText();
            var $label = this.$(".header .drill-down-label");
            $label.find(".text").text(text);
            $label.toggle(text.length > 0)
            return text;
        },

        getDrillDownSelectionText: function() {
            var attrs = this.model.getInteractiveFilterModel().attributes;
            var sel = [];
            var that = this;
            _.each(attrs, function(v, k) {
                if (!v) return;
                let a = v.split(" "), i;
                if (k === "tags" || k === "topics" || k === "segments") {
                    if (deprecatedTagsStore.list) {
                        let i = a.indexOf('/'), tag;
                        if (i < 0) {
                            for (i = 0; i < a.length; i++) {
                                let id = a[i]
                                if (id !== ".") {   // dot is 'All conversation' option so ignore
                                    id = parseInt(id)
                                    tag = deprecatedTagsStore.get(Math.abs(id));
                                    sel.push((id < 0 ? "Excluding " : "") + (tag ? tag.name : ("tag:" + a[i])));
                                }
                            }
                        } else {
                            let s = "";
                            let first = true;
                            for (i = 0; i < a.length; i++) {
                                let id = a[i];
                                if (id === '/') {
                                    if (s) s += " and ";
                                    first = true;
                                } else {
                                    if (first) first = false;
                                    else s += " or ";
                                    tag = deprecatedTagsStore.get(id);
                                    s += tag ? tag.name : ("tag:" + id);
                                }
                            }
                            if (s) sel.push(s);
                        }
                    } else {
                        deprecatedTagsStore.refresh(true).then(() => that.updateDrillDownSelection())
                    }

                } else if (k === "brand") {
                    if (deprecatedBrandsStore.list) {
                        for (let i = 0; i < a.length; i++) {
                            if (a[i] === '/') continue;
                            let brand = deprecatedBrandsStore.get(a[i]);
                            if (brand) {
                                let s = [];
                                for (; brand; brand = brand.parent) s.push(brand.shortName || brand.name);
                                sel.push(s.reverse().join(": "));
                            } else {
                                sel.push("brand:" + a[i]);
                            }
                        }
                    } else {
                        deprecatedBrandsStore.refresh(true).then(() => that.updateDrillDownSelection());
                    }
                } else if (k === "media") {
                    for (i = 0; i < a.length; i++) sel.push(MEDIA_CATEGORIES[a[i]] || a[i]);
                } else if (k === "country") {
                    for (i = 0; i < a.length; i++) sel.push(Beef.LocationPicker.getCountryName(a[i]) || a[i]);
                } else if (k === "credibility") {
                    for (i = 0; i < a.length; i++) sel.push("Credibility " + a[i]);
                } else if (k === "_showWhatDrilldownSelection") {
                    switch(a[0]) {
                        case '.': break;
                        case 'operational': sel.push("Operational mentions"); break;
                        case 'reputational': sel.push("Reputational mentions"); break;
                        case 'risk': sel.push("Risk mentions"); break;
                        case 'press': sel.push("Press mentions"); break;
                        default:
                            console.warn("Unhandled", a);
                            sel.push(a[0] + " mentions");
                    }
                } else if (k === "_cxSelectorSelection") {
                    switch(a[0]) {
                        case 'all': sel.push("All CX mentions"); break;
                        case '4': sel.push("Service only mentions"); break;
                        case '3': sel.push("Cancel mentions"); break;
                        case '2': sel.push("Purchase mentions"); break;
                        case 'non': sel.push("Non CX mentions"); break;
                        default:
                            console.warn("Unhandled", a);
                            sel.push(a[0] + " mentions");
                    }
                } else if (k === "visibility") {
                    for (i = 0; i < a.length; i++) {
                        if (a[i] === '.') continue;
                        switch(a[i]) {
                            case 'PUBLIC': sel.push("Public mentions"); break;
                            case 'DIRECT_MESSAGE': sel.push("Direct messages"); break;
                            default:
                                sel.push("Visibility " + a[i]);
                        }
                    }
                } else if (k === "published") {
                    try {
                        i = v.indexOf('-');
                        var start = v.substring(0, i);
                        var end = v.substring(i + 1);
                        var s;
                        if (start == end) { // day
                            s = moment(start).format("ddd DD MMM YYYY");
                        } else {
                            var first = moment(start);
                            var last = first.clone().add({days: 6});
                            if (last.format("YYYY/MM/DD") == end) {        // week
                                var mdiff = first.month() != last.month();
                                s = first.format("ddd DD");
                                if (!mdiff) {
                                    s += last.format(" - DD MMM YYYY")
                                } else {
                                    s += first.format(" MMM") + last.format(" - DD MMM YY")
                                }
                            } else {
                                last = first.clone().add({months: 1}).add({days: -1});
                                if (last.format("YYYY/MM/DD") == end) {        // month
                                    s = first.format("MMMM YYYY");
                                } else {
                                    last = first.clone().add({years: 1}).add({days: -1});
                                    if (last.format("YYYY/MM/DD") == end) s = first.format("YYYY"); // year
                                }
                            }
                        }
                        sel.push(s ? s : v);
                    } catch (e) {
                        console.error("Bad date range [" + v + "]: " +e, e);
                        sel.push(v);
                    }
                } else if (k === "gender") {
                    for (i = 0; i < a.length; i++) sel.push(GENDER_ITEMS[a[i]] || a[i]);
                } else if (k === "language") {
                    for (i = 0; i < a.length; i++) sel.push(LANGUAGES[a[i].toLowerCase()] || a[i]);
                } else if (k === "sentiment") {
                    for (i = 0; i < a.length; i++) sel.push(Beef.SentimentPicker.items[a[i].toLowerCase()] || a[i]);
                } else if (k === "link") {
                    for (i = 0; i < a.length; i++) sel.push(a[i]);
                }
            });
            return sel.join(", ");
        },

        getJsonFromSnoek: function(endpoint, data, callback) {
            data = data || {};
            if (Beef.Dashboard.isPresentationMode() && !data.cacheValidFor) { // cache for longer in fullscreen mode
                data.cacheValidFor = this.getAutoRefreshMins() * 60;
            }
            return Beef.Dashboard.getJsonFromSnoek(endpoint, data, callback);
        },

        /**
         * Calls data from grouse. Manages browser caching for grouse. Returns a
         * promise.
         *
         * Importantly, this handles browser cache tokens for the API.
         */
        getJsonFromGrouse: function(endpoint, data) {
            data = data || {};
            if (Beef.Dashboard.isPresentationMode() && !data.cacheValidFor) { // cache for longer in fullscreen mode
                data.cacheValidFor = this.getAutoRefreshMins() * 60;
            }
            return Beef.Dashboard.getJsonFromGrouse(endpoint, data);
        }
    });

    /**
     * Deep-clone attrs leaving out fields starting with underscore and converting Backbone models to JSON.
     */
    var cloneSection = function(attrs) {
        return prepareDataImpl(attrs);
    };

    var prepareDataImpl = function(attrs) {
        var i, ans;
        if (!attrs) return attrs;
        if (isFunction(attrs.toJSON)) attrs = attrs.toJSON();
        if (Array.isArray(attrs)) {
            ans = [];
            for (i = 0; i < attrs.length; i++) {
                ans.push(prepareDataImpl(attrs[i]));
            }
            return ans;
        } else if (isObject(attrs)) {
            ans = {};
            for (i in attrs) {
                if (i.charAt(0) != '_') ans[i] = prepareDataImpl(attrs[i]);
            }
            return ans;
        } else {
            return attrs;
        }
    };

    /**
     * Deep-clone attrs leaving out fields starting with underscore and converting Backbone models to JSON.
     */
    var cloneWidget = function(attrs) {
        return widgetDataImpl(attrs);
    };

    var widgetDataImpl = function(attrs) {
        var i, ans;
        if (!attrs) return attrs;
        if (isFunction(attrs.toJSON)) attrs = attrs.toJSON();
        if (Array.isArray(attrs)) {
            ans = [];
            for (i = 0; i < attrs.length; i++) {
                ans.push(prepareDataImpl(attrs[i]));
            }
            return ans;
        } else if (isObject(attrs)) {
            ans = {};
            for (i in attrs) {
                if (i == 'width' || i == 'height' || i == 'type' ||
                    i == '_xml' || i == '_text' || i == 'caption' ||
                    i == '_params') {
                    ans[i] = prepareDataImpl(attrs[i]);
                }
            }
            return ans;
        } else {
            return attrs;
        }
    };
});